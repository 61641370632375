.footer {
  padding: 9px 18px 18px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media(min-width: 750px) {
  .footer {
    padding: 1px 18px 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
  }
}

.footer__logo {
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #ffffff;
}

@media(min-width: 750px) {
  .footer__logo {
    float: left;
    padding-top: 8px;
    padding-right: 16px;
  }
}

.footer__menu {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}

@media(min-width: 750px) {
  .footer__menu {
    float: left;
  }
}

.footer__menu-item {
  text-align: left;
}

@media(min-width: 750px) {
  .footer__menu-item {
    display: inline-block;
    padding: 0 16px;
  }
}

.footer__menu-link {
  opacity: 0.71;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.67;
  color: #ffffff;
  padding-top: 9px;
  display: block;
  -webkit-text-size-adjust: 100%;
}

.footer__copy {
  padding-top: 9px;
  color: white;
  opacity: 0.81;
  -webkit-text-size-adjust: 100%;
}

@media(min-width: 750px) {
  .footer__copy {
    float: right;
    padding-top: 10px;
  }
}
.valid-seed__select-container .pages-select__block:first-child {
  padding-right: 22px;
}

.pages-select__container {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

@media(min-width:1200px) {
  .pages-select__container:before {
    content: '';
    background-color: #d2ded9;
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    top: 20px;
  }
}

.pages-select__container-position {
  display: inline-block;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
}

@media(min-width: 750px) {
  .pages-select__container-position {
    padding: 0 7px 0 15px;
  }
}

.pages-select__container-position .pages-select__block + .pages-select__block:not(.pages-select__block--words) {
  margin-left: 40px;
}


@media(min-width: 750px) {
  .pages-select__container-position .pages-select__block + .pages-select__block {
    margin-left: 70px;
  }
  .pages-select__container-position .pages-select__block--words .pages-select__control {
    width: 58px;
  }
}

@media(min-width: 1200px) {
  .pages-select__container-position .pages-select__block + .pages-select__block {
    margin-left: 32px;
  }
}

.pages-select__container-position--words .pages-select__block {
  margin-left: 0;
}

.pages-select__block {
  line-height: 1.4;
  display: inline-block;
}

.pages-select__block--words {
  display: block;
}

@media(min-width: 750px) {
  .pages-select__block--words {
    display: inline-block;
  }
}

.pages-select__block>div {
  display: inline-block;
  vertical-align: middle;
}

.pages-select__block>div:hover svg, .pages-select__block>div:active svg {
  fill: #6da7ac;
}

.pages-select__block .pages-select__single-value {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.91);
}

@media(min-width:750px) {
  .pages-select__block .pages-select__single-value {
    font-size: 24px;
  }
}

.pages-select__block .pages-select__single-value--is-disabled {
  color: rgba(0,0,0,.5);
}

@media(min-width:750px) {
  .pages-select__single-value {
    font-size: 24px;
  }
}

.pages-select__block>div:hover .pages-select__single-value {
  color: #669ca0;
}

.pages-select__text {
  margin: 0;
  font-size: 12px;
  display: inherit;
  position: relative;
  top: -1px;
}

@media(min-width:750px) {
  .pages-select__text {
    font-size: 18px;
    top: 0;
  }
}

.pages-select__block .pages-select__control {
  border: none;
  box-shadow: none;
  background-color: #fff;
  min-height: 32px;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__control {
    width: 44px;
  }
  .pages-select__container-position--words .pages-select__control {
    width: 58px;
  }
}

@media(min-width: 750px) {
  .pages-select__container-position--words {
    display: none;
  }
}

.pages-select__block .pages-select__value-container {
  padding: 2px 4px;
}

.pages-select__block .pages-select__indicator {
  padding: 0;
}

@media(min-width: 750px) {
  .pages-select__indicator {
    padding: 8px;
  }
}

.pages-select__indicator-separator {
  display: none;
}

.pages-select__dropdown-indicator svg {
  fill: #5f5f5f;
  width: 12px;
  height: 12px;
}

.pages-select__block .pages-select--is-disabled:hover {
  cursor: not-allowed;
}

.pages-select__block .pages-select--is-disabled .pages-select__dropdown-indicator svg {
  fill: rgba(0,0,0,.5);
}

@media(min-width:750px) {
  .pages-select__dropdown-indicator svg {
    width: 22px;
    height: 22px;
  }
}

.pages-select__block .pages-select__menu {
  width: 250px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.26), 1px 1px 2px 0 rgba(0, 0, 0, 0.17);
  border-radius: 0px;
  margin: 0;
  bottom: 100%;
  top: unset;
  right: -90px;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__menu {
    right: -288px;
    width: 336px;
  }
  .pages-select__block--words .pages-select__menu {
    right: 0;
  }
}

@media(min-width: 1200px) {
  .pages-select__block--words .pages-select__menu {
    right: -288px;
  }
}

.pages-select__block .pages-select__menu-list {
  padding: 0;
}

.pages-select__block .pages-select__group {
  padding: 0;
  height: 140px;
  display: flex;
  flex-direction: row;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__group {
    height: 200px;
  }
}

.pages-select__group .pages-select__group-heading {
  width: 200px;
  padding: 24px 7px 36px 24px;
  font-size: 11px;
  line-height: 1.81;
  letter-spacing: -0.1px;
  color: rgba(0, 0, 0, 0.72);
  text-transform: initial;
  font-family: 'Charter-Roman', serif;
  text-align: left;
}

@media(min-width:750px) {
  .pages-select__group .pages-select__group-heading {
    font-size: 15px;
    width: 257px;
  }
}

.pages-select__group>div:last-child {
  width: 50px;
  border-left: solid 1px #c8e0e2;
  display: table;
  padding: 16px 0;
}

@media(min-width:750px) {
  .pages-select__group>div:last-child {
    width: 78px;
  }
}

.pages-select__block .pages-select__option {
  padding: 4px 12px;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.91);
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__option {
    font-size: 24px;
  }
}

.pages-select__block .pages-select__option--is-focused,
.pages-select__block .pages-select__option--is-selected {
  background-color: rgba(115, 175, 180, 0.39);
  color: rgba(0, 0, 0, 0.9);
}
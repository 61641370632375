.page {
  text-align: left;
  width: 240px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto 22px;
  display: inline-block;
  vertical-align: top;
  padding: 0 7px;
}

@media(min-width: 750px) {
  .page {
    width: 33.33333333%;
    margin: 0 0 14px;
  }
}

.page__position {
  border-radius: 2px;
  height: 228px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.51), rgba(250, 250, 250, 0.51));
  border: solid 1px #dfe4e0;
  border-bottom: 2px solid #a3b8c1;
  padding: 10px 19px 30px;
  box-sizing: border-box;
}

.page__position:hover {
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: solid 2px #6ea9ad;
}

@media(min-width:1600px) {
  .page__position {
    height: 233px;
  }
}

.page__position:hover .page__copy {
  opacity: 1;
}

.page__headline {
  display: table;
  width: 100%;
}

.page__headline--restore {
  opacity: 0;
}

.page__text {
  font-family: 'Charter-Roman', serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.87;
  color: rgba(0, 0, 0, 0.4);
  float: left;
  margin: 0;
}

.page__img {
  float: right;
  margin-top: 10px;
}

.page__form:focus {
  outline: none;
}

.page__textarea {
  height: 160px;
  width: 100%;
  resize: none;
  border: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.78;
  color: rgba(0, 0, 0, 0.91);
  margin: 0;
  overflow: hidden;
}

@media(min-width: 1200px) {
  .page__textarea {
    font-size: 18px;
  }
}

.page__textarea:focus {
  outline: none;
}

.page__copy {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 0;
  border: 0;
  float: right;
  -webkit-text-size-adjust: 100%;
}

@media(min-width: 1200px) {
  .page__copy {
    font-size: 18px;
    opacity: 0;
  }
}

.page__copy:hover, .page__copy:active {
  color: #689fa3;
}
body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

a {
	color: inherit;
}

.title {
    margin-top: 0;
    text-align: right;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    color: #132029;
    position: relative;
    z-index: 1;
    line-height: 1.75;
    letter-spacing: -1.5px;
    padding: 0 24px;
    margin-bottom: -16px;
}

@media(min-width: 750px) {
    .title {
        font-size: 64px;
        line-height: 0.88;
        padding: 0 65px;
        margin-bottom: 0;
        letter-spacing: -3px;
        margin-bottom: -3px;
    }
}

@media(min-width: 1200px) {
    .title {
        padding: 0;
    }
}

.title a {
	text-decoration: none;
	color: inherit;
}
.valid-seed__select-container .pages-select__block:first-child {
  padding-right: 22px;
}

.pages-select__container {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

@media(min-width:1200px) {
  .pages-select__container:before {
    content: '';
    background-color: #d2ded9;
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    top: 20px;
  }
}

.pages-select__container-position {
  display: inline-block;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
}

@media(min-width: 750px) {
  .pages-select__container-position {
    padding: 0 7px 0 15px;
  }
}

.pages-select__container-position .pages-select__block + .pages-select__block:not(.pages-select__block--words) {
  margin-left: 40px;
}


@media(min-width: 750px) {
  .pages-select__container-position .pages-select__block + .pages-select__block {
    margin-left: 70px;
  }
  .pages-select__container-position .pages-select__block--words .pages-select__control {
    width: 58px;
  }
}

@media(min-width: 1200px) {
  .pages-select__container-position .pages-select__block + .pages-select__block {
    margin-left: 32px;
  }
}

.pages-select__container-position--words .pages-select__block {
  margin-left: 0;
}

.pages-select__block {
  line-height: 1.4;
  display: inline-block;
}

.pages-select__block--words {
  display: block;
}

@media(min-width: 750px) {
  .pages-select__block--words {
    display: inline-block;
  }
}

.pages-select__block>div {
  display: inline-block;
  vertical-align: middle;
}

.pages-select__block>div:hover svg, .pages-select__block>div:active svg {
  fill: #6da7ac;
}

.pages-select__block .pages-select__single-value {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.91);
}

@media(min-width:750px) {
  .pages-select__block .pages-select__single-value {
    font-size: 24px;
  }
}

.pages-select__block .pages-select__single-value--is-disabled {
  color: rgba(0,0,0,.5);
}

@media(min-width:750px) {
  .pages-select__single-value {
    font-size: 24px;
  }
}

.pages-select__block>div:hover .pages-select__single-value {
  color: #669ca0;
}

.pages-select__text {
  margin: 0;
  font-size: 12px;
  display: inherit;
  position: relative;
  top: -1px;
}

@media(min-width:750px) {
  .pages-select__text {
    font-size: 18px;
    top: 0;
  }
}

.pages-select__block .pages-select__control {
  border: none;
  box-shadow: none;
  background-color: #fff;
  min-height: 32px;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__control {
    width: 44px;
  }
  .pages-select__container-position--words .pages-select__control {
    width: 58px;
  }
}

@media(min-width: 750px) {
  .pages-select__container-position--words {
    display: none;
  }
}

.pages-select__block .pages-select__value-container {
  padding: 2px 4px;
}

.pages-select__block .pages-select__indicator {
  padding: 0;
}

@media(min-width: 750px) {
  .pages-select__indicator {
    padding: 8px;
  }
}

.pages-select__indicator-separator {
  display: none;
}

.pages-select__dropdown-indicator svg {
  fill: #5f5f5f;
  width: 12px;
  height: 12px;
}

.pages-select__block .pages-select--is-disabled:hover {
  cursor: not-allowed;
}

.pages-select__block .pages-select--is-disabled .pages-select__dropdown-indicator svg {
  fill: rgba(0,0,0,.5);
}

@media(min-width:750px) {
  .pages-select__dropdown-indicator svg {
    width: 22px;
    height: 22px;
  }
}

.pages-select__block .pages-select__menu {
  width: 250px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.26), 1px 1px 2px 0 rgba(0, 0, 0, 0.17);
  border-radius: 0px;
  margin: 0;
  bottom: 100%;
  top: unset;
  right: -90px;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__menu {
    right: -288px;
    width: 336px;
  }
  .pages-select__block--words .pages-select__menu {
    right: 0;
  }
}

@media(min-width: 1200px) {
  .pages-select__block--words .pages-select__menu {
    right: -288px;
  }
}

.pages-select__block .pages-select__menu-list {
  padding: 0;
}

.pages-select__block .pages-select__group {
  padding: 0;
  height: 140px;
  display: flex;
  flex-direction: row;
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__group {
    height: 200px;
  }
}

.pages-select__group .pages-select__group-heading {
  width: 200px;
  padding: 24px 7px 36px 24px;
  font-size: 11px;
  line-height: 1.81;
  letter-spacing: -0.1px;
  color: rgba(0, 0, 0, 0.72);
  text-transform: none;
  text-transform: initial;
  font-family: 'Charter-Roman', serif;
  text-align: left;
}

@media(min-width:750px) {
  .pages-select__group .pages-select__group-heading {
    font-size: 15px;
    width: 257px;
  }
}

.pages-select__group>div:last-child {
  width: 50px;
  border-left: solid 1px #c8e0e2;
  display: table;
  padding: 16px 0;
}

@media(min-width:750px) {
  .pages-select__group>div:last-child {
    width: 78px;
  }
}

.pages-select__block .pages-select__option {
  padding: 4px 12px;
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.91);
}

@media(min-width: 750px) {
  .pages-select__block .pages-select__option {
    font-size: 24px;
  }
}

.pages-select__block .pages-select__option--is-focused,
.pages-select__block .pages-select__option--is-selected {
  background-color: rgba(115, 175, 180, 0.39);
  color: rgba(0, 0, 0, 0.9);
}
.page {
  text-align: left;
  width: 240px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto 22px;
  display: inline-block;
  vertical-align: top;
  padding: 0 7px;
}

@media(min-width: 750px) {
  .page {
    width: 33.33333333%;
    margin: 0 0 14px;
  }
}

.page__position {
  border-radius: 2px;
  height: 228px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.51), rgba(250, 250, 250, 0.51));
  border: solid 1px #dfe4e0;
  border-bottom: 2px solid #a3b8c1;
  padding: 10px 19px 30px;
  box-sizing: border-box;
}

.page__position:hover {
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: solid 2px #6ea9ad;
}

@media(min-width:1600px) {
  .page__position {
    height: 233px;
  }
}

.page__position:hover .page__copy {
  opacity: 1;
}

.page__headline {
  display: table;
  width: 100%;
}

.page__headline--restore {
  opacity: 0;
}

.page__text {
  font-family: 'Charter-Roman', serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.87;
  color: rgba(0, 0, 0, 0.4);
  float: left;
  margin: 0;
}

.page__img {
  float: right;
  margin-top: 10px;
}

.page__form:focus {
  outline: none;
}

.page__textarea {
  height: 160px;
  width: 100%;
  resize: none;
  border: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.78;
  color: rgba(0, 0, 0, 0.91);
  margin: 0;
  overflow: hidden;
}

@media(min-width: 1200px) {
  .page__textarea {
    font-size: 18px;
  }
}

.page__textarea:focus {
  outline: none;
}

.page__copy {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 0;
  border: 0;
  float: right;
  -webkit-text-size-adjust: 100%;
}

@media(min-width: 1200px) {
  .page__copy {
    font-size: 18px;
    opacity: 0;
  }
}

.page__copy:hover, .page__copy:active {
  color: #689fa3;
}
.btn {
    background-image: none;
    display: inline-block;
    text-align: center;
    border: none;
    border-radius: 1px;
    font-weight: bold;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.17);
    line-height: 3.43;
    font-size: 14px;
    line-height: 29px;
}

@media(min-width: 750px) {
    .btn {
        font-size: 28px;
        height: 64px;
        line-height: 64px;
        line-height: 1.71;
    }
}

.btn--green {
    width: 136px;
    background-image: linear-gradient(to bottom, #74b2b6, #669b9f);
    color: #fff;
}

@media(min-width: 750px) {
    .btn--green {
        width: 270px;
    }
}

.btn--white {
    width: 80px;
    background: #e8f1f2;
    color: #626566;
}

@media(min-width: 750px) {
    .btn--white {
        width: 160px;
    }
}

.btn--white:hover {
    background-image: linear-gradient(#e7bebe, #e7bebe), linear-gradient(to bottom, #74b2b6, #669b9f);
    color: #61585a;
}

.btn--valid-seed {
    position: relative;
    top: -21px;
}

@media(min-width:750px) {
    .btn--valid-seed {
        top: -34px;
    }
}

.btn[disabled]{
    opacity: 0.7;
    cursor: not-allowed;
}
h3 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.71;
  text-align: left;
  color: rgba(0, 0, 0, 0.72);
  margin: 0;
}

/*@media(min-width: 750px) {
  .generate__container {
    padding-bottom: 68px;
  }
}

@media(min-width: 1600px) {
  .generate__container {
    padding-bottom: 42px;
  }
}*/

.generate__title {
  margin-bottom: 8px;
}

.generate__title-with-btn {
  margin: 0 auto;
  display: table;
  top: -24px;
  position: relative;
}

@media(min-width: 750px) {
  .generate__title-with-btn {
    top: -34px;
  }
}

.generate__title-with-btn+.pages-select__container {
  margin-top: -17px;
  margin-bottom: 8px;
  top: 0;
}

@media(min-width:750px) {
  .generate__title-with-btn+.pages-select__container {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

@media(min-width:1200px) {
  .generate__title-with-btn+.pages-select__container {
    margin-top: -9px;
  }
}

@media(min-width:1600px) {
  .generate__title-with-btn+.pages-select__container {
    margin-top: 9px;
  }
}

.generate__title-with-btn .btn--white {
  margin-left: 16px;
}

.generate__list {
  display: table;
  width: 100%;
  padding-bottom: 15px;
  text-align: center;
}

@media(min-width:750px) {
  .generate__list {
    padding-top: 48px;
    padding-bottom: 0;
  }
}

@media(min-width: 1200px) {
  .generate__list {
    padding-top: 38px;
  }
}

@media(min-width: 1600px) {
  .generate__list {
    padding-top: 38px;
  }
}

.generate__list-position {
  margin: 0 -7px;
}

.generate__page {
  text-align: left;
  width: 240px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto 22px;
  display: inline-block;
  vertical-align: top;
  padding: 0 7px;
}

@media(min-width: 750px) {
  .generate__page {
    width: 33.33333333%;
    margin: 0 0 14px;
  }
}

.generate__page-position {
  border-radius: 2px;
  height: 228px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.51), rgba(250, 250, 250, 0.51));
  border: solid 1px #dfe4e0;
  border-bottom: 2px solid #a3b8c1;
  padding: 10px 19px 30px;
  box-sizing: border-box;
}

.generate__page-position:hover {
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: solid 2px #6ea9ad;
}

@media(min-width:1600px) {
  .generate__page-position {
    height: 233px;
  }
}

.generate__page-position:hover .generate__page-copy {
  opacity: 1;
}

.generate__page-headline {
  display: table;
  width: 100%;
}

.generate__page-headline--restore {
  opacity: 0;
}

.generate__page-text {
  font-family: 'Charter-Roman', serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.87;
  color: rgba(0, 0, 0, 0.4);
  float: left;
  margin: 0;
}

.generate__page-img {
  float: right;
  margin-top: 10px;
}

.generate__page-form:focus {
  outline: none;
}

.generate__page-textarea {
  height: 160px;
  width: 100%;
  resize: none;
  border: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.78;
  color: rgba(0, 0, 0, 0.91);
  margin: 0;
  overflow: hidden;
}

@media(min-width: 1200px) {
  .generate__page-textarea {
    font-size: 18px;
  }
}

.generate__page-textarea:focus {
  outline: none;
}

.generate__page-copy {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 0;
  border: 0;
  float: right;
  -webkit-text-size-adjust: 100%;
}

@media(min-width: 1200px) {
  .generate__page-copy {
    font-size: 18px;
    opacity: 0;
  }
}

.generate__page-copy:hover, .generate__page-copy:active {
  color: #689fa3;
}
.icon-arrow {
  width: 13px;
  height: 8px;
}

.icon-arrow-restore-seed {
  width: 18px;
  height: 10px;
  opacity: 0;
}

.icon-arrow-restore-seed--valid {
  fill: #379299;
  opacity: 1;
}
.restore-seed__container {
  padding-bottom: 42px;
}

@media(min-width: 750px) {
  .restore-seed__container {
    padding-bottom: 68px;
  }
}

@media(min-width: 1600px) {
  .restore-seed__container {
    padding: 0 0 42px;
  }
}

.restore__link {
  background-color: #fff;
  text-decoration: none;
}

.restore__link .icon-arrow {
  margin-top: 3px;
  margin-right: 8px;
  height: 8px;
  width: 12px;
  fill: #474747;
}

@media(min-width: 750px) {
  .restore__link .icon-arrow {
    height: 14px;
    width: 20px;
    margin-right: 22px;
  }
}

@media(min-width: 1200px) {
  .restore__link .icon-arrow {
    height: 18px;
    width: 18px;
    margin-right: 22px;
    margin-top: 5px;
  }
}

.restore__link:hover .icon-arrow, .restore__link:active .icon-arrow {
  fill: #6da7ac;
}

.restore__link {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  width: 100%;
  border: 0;
  padding: 0;
}

@media(min-width: 750px) {
  .restore__link {
    height: 164px;
  }
}

@media(min-width:1200px) {
  .restore__link {
    height: 84px;
  }
}

@media(min-width:1600px) {
  .restore__link {
    height: 184px;
  }
}

.restore__link:hover .restore__title {
  color: #6da7ac;
}

.restore__img {
  display: inline-block;
  padding-top: 5px;
}

.restore__title {
  font-size: 14px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.72);
  font-weight: bold;
}

.restore-seed__title {
  font-size: 14px;
  text-align: center;
  position: relative;
  font-weight: bold;
}
  
@media(min-width: 750px) {
  .restore__title, .restore-seed__title {
    font-size: 28px;
  }
  .restore-seed__title {
    top: 0;
    margin-bottom: 4px;
    margin-top: 22px;
  }
}

@media(min-width: 1200px) {
  .restore-seed__title {
    margin-top: 10px;
    margin-bottom: 16px;
  }
}

.restore-seed__title-with-btn {
  text-align: center;
  position: relative;
  width: 100%;
  top: -24px;
  font-size: 14px;
  font-weight: bold;
}

@media(min-width: 750px) {
  .restore-seed__title-with-btn {
    top: -34px;
  }
}

.restore-seed__title-with-btn+.pages-select__container {
  top: 0;
  margin-bottom: 8px;
  margin-top: 16px;
}

@media(min-width:750px) {
  .restore-seed__title-with-btn+.pages-select__container {
    margin-bottom: 0;
    margin-top: 9px;
  }
}


.btn--restore-seed {
  display: none;
}

@media(min-width:750px) {
  .btn--restore-seed {
    display: inline-block;
  }
}

.btn--restore-seed-mobile {
  display: inline-block;
}

@media(min-width: 750px) {
  .btn--restore-seed-mobile {
    display: none !important;
  }
}

.restore-seed__list {
  display: table;
  width: 100%;
  text-align: center;
}

@media(min-width: 750px) {
  .restore-seed__list {
    padding-top: 48px;
  }
}

@media(min-width: 1200px) {
  .restore-seed__list {
    padding-top: 38px;
  }
}

@media(min-width: 1200px) {
  .restore-seed__list-position {
    margin: 0 -7px;
  }
}

.restore-seed__page-headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  opacity: 0;
}

.restore-seed__page-headline--restore {
  opacity: 1;
}

.restore-seed__page {
  width: 240px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto 22px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding: 0 7px;
}

@media(min-width: 750px) {
  .restore-seed__page {
    width: 33.33333333%;
    margin: 0 0 14px;
  }
}

.restore-seed__page-position {
  border-radius: 2px;
  height: 228px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.51), rgba(250, 250, 250, 0.51));
  border: solid 1px #dfe4e0;
  border-bottom: 2px solid #a3b8c1;
  padding: 10px 19px 30px;
  box-sizing: border-box;
}

@media(min-width: 1600px) {
  .restore-seed__page-position {
    height: 233px;
  }
}

.restore-seed__page-position:hover {
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.17);
  border-bottom: solid 2px #6ea9ad;
}

.restore-seed__text {
  font-family: 'Charter-Roman', serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.87;
  color: rgba(0, 0, 0, 0.4);
  float: left;
  margin: 0;
}

.restore-seed__page-form {
  position: relative;
  height: 160px;
}

.restore-seed__page-form:focus {
  outline: none;
}

.restore-seed__page-textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  resize: none;
  border: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 32px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  padding-right: 3px;
  box-sizing: border-box;
}

@media(min-width: 750px) {
  .restore-seed__page-textarea {
    font-size: 18px;
    padding-right: 5px;
  }
}

.restore-seed__page-textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
  text-shadow: none;
  -webkit-text-fill-color: initial;
}

.restore-seed__page-textarea:focus {
  outline: none;
}

.restore-seed__page-text {
  position: relative;
  display: inline-block;
  font-size: 15px;
  margin: 0;
  padding-right: 4px;
  color: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 32px;
}

@media(min-width:750px) {
  .restore-seed__page-text {
    font-size: 18px;
    padding-right: 5px;
  }
}

.restore-seed__page-text--invalid {
  color: rgba(127, 23, 23, 0.9);
}
.seed-constructor {
  background-color: #fff;
}

.splitter__container {
  text-align: center;
  position: relative;
}

/*@media(min-width: 750px) {
  .splitter__container {
    padding-bottom: 70px;
  }
}

@media(min-width: 1200px) {
  .splitter__container {
    padding-bottom: 81px;
  }
}*/

.footer {
  padding: 15px;
  background-color: #5b636a;
  box-shadow: inset 0 0 33px 0 rgba(0, 0, 0, 0.03);
  color: #fff;
  font-family: OpenSans, sans-serif;
  font-size: 12px;
}

@media(min-width: 1600px) {
  .footer {
    padding: 15px 0;
  }
}
.footer {
  padding: 9px 18px 18px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media(min-width: 750px) {
  .footer {
    padding: 1px 18px 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
  }
}

.footer__logo {
  font-family: OpenSans, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.67;
  color: #ffffff;
}

@media(min-width: 750px) {
  .footer__logo {
    float: left;
    padding-top: 8px;
    padding-right: 16px;
  }
}

.footer__menu {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}

@media(min-width: 750px) {
  .footer__menu {
    float: left;
  }
}

.footer__menu-item {
  text-align: left;
}

@media(min-width: 750px) {
  .footer__menu-item {
    display: inline-block;
    padding: 0 16px;
  }
}

.footer__menu-link {
  opacity: 0.71;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.67;
  color: #ffffff;
  padding-top: 9px;
  display: block;
  -webkit-text-size-adjust: 100%;
}

.footer__copy {
  padding-top: 9px;
  color: white;
  opacity: 0.81;
  -webkit-text-size-adjust: 100%;
}

@media(min-width: 750px) {
  .footer__copy {
    float: right;
    padding-top: 10px;
  }
}
.faq {
	padding: 30px 15px;
	color: rgba(0, 0, 0, 0.91);
}

.faq-item {
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.faq-item__header {
	font-size: 26px;
}

.faq-item__text {
	line-height: 1.33;
}
section {
  height: 1;
}

.print-container {
  font-family: Arial, sans-serif;
  color: #000;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.print-container * {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.sheet__header {
  height: 123px;
  background-color: #f3f3f3 !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  padding-top: 18px;
  box-sizing: border-box;
  padding-left: 72px;
}

.sheet__header-title {
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 64px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: -3px;
  color: #60686e;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  margin: 0;
  margin-bottom: 15px;
}

.sheet__header-page{
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.72);
  margin: 0;
}

.sheet__words {
  height: 325px;
  padding-top: 60px;
  box-sizing: border-box;
  padding-left: 72px;
}

.sheet__words-column {
  width: 50%;
  float: left;
}

.sheet__word {
  font-family: Charter;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: 0.9px;
  color: rgba(0, 0, 0, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.sheet__word-number {
  font-family: Charter;
  color: rgba(134, 134, 134, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.print-container h4 {
  font-size: 18px;
  font-weight: normal;
  color: #000;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.manual {
  /*
    position: absolute;
    bottom: 0;
    margin-left: 10%;
    margin-top: 80px;
    width: 80%;
    height: 200px;
  */
}

.manual__text {
  background-color: #f3f3f3 !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  padding-top: 19px;
  padding-bottom: 19px;
  font-size: 16px;
  padding-left: 72px;
  padding-right: 59px;
}

.manual h4 {
  font-family: inherit;
  font-weight: normal;
}

.manual__cards {
  padding-left: 72px;
  padding-right: 59px;
}

.manual__cards-title {
  margin-top: 30px;
  margin-bottom: 28px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
body.A3           .sheet { width: 297mm; height: 419mm }
body.A3.landscape .sheet { width: 420mm; height: 296mm }
body.A4           .sheet { width: 210mm; height: 296mm }
body.A4.landscape .sheet { width: 297mm; height: 209mm }
body.A5           .sheet { width: 148mm; height: 209mm }
body.A5.landscape .sheet { width: 210mm; height: 147mm }

/** Padding area **/
.sheet.padding-10mm { padding: 10mm }
.sheet.padding-15mm { padding: 15mm }
.sheet.padding-20mm { padding: 20mm }
.sheet.padding-25mm { padding: 25mm }

/** For screen preview **/
@media screen {
  body.A3,
  body.A4,
  body.A5 {
    background: #e0e0e0
  }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
           body.A3.landscape { width: 420mm }
  body.A3, body.A4.landscape { width: 297mm }
  body.A4, body.A5.landscape { width: 210mm }
  body.A5                    { width: 148mm }


  .main {
    padding: 0 !important;
  }
}

.print-cards {
  margin-bottom: 30px;
}

.print-card {
  display: inline-block;
  box-sizing: border-box !important;
  width: 25%;
  vertical-align: top;
}

.print-card__number {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: rgba(129, 129, 129, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.print-card__inner {
  min-height: 170px;
  font-size: 12px;
  /*padding: 5px;
  border: 1px solid #dfe4e0;*/
}

.print-card__word {
  color: rgba(0, 0, 0, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  font-size: 12px;
  line-height: 20px;
}

.print-card__word span{
  color: rgba(133, 133, 133, 0.91);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.print-seed {
  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.91);
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.print-splitter {
  background-color: #c3c3c3;
  border: solid 1px #c3c3c3;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
@font-face {
    font-family: 'Helvetica Neue';
    font-weight: normal;
    font-style: normal;
    src: url(/static/media/HelveticaNeue.5754a536.ttf);
}

@font-face {
    font-family: 'Charter-Roman';
    font-weight: normal;
    font-style: normal;
    src: url(/static/media/charterc.483c91ba.otf);
}

@font-face {
    font-family: 'OpenSans';
    font-weight: normal;
    font-style: normal;
    src: url(/static/media/OpenSans-Regular.d7d5d458.ttf);
}

@font-face {
    font-family: 'OpenSans';
    font-weight: bold;
    font-style: normal;
    src: url(/static/media/OpenSans-Bold.f5331cb6.ttf);
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
}

body {
    background-color: #fff;
}


#root {
    height: inherit;
}

.main {
    min-height: 100%;
    position: relative;
    padding-bottom: 160px;
    box-sizing: border-box;
}

@media(min-width:750px) {
  .main {
    padding-bottom: 40px;
  }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

label {
    font-size: 20px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    float: left;
}

button {
    cursor: pointer;
}

button:focus {
    outline: none;
}

button.link {
    background: none;
    border: none;
    font-size: 15px;
    color: #000;
    letter-spacing: 0px;
    font-family: 'Calibri', serif;
    font-weight: bold;
    margin-right: 40px;
    display: block;
    margin-top: 30px;
    float: right;
    text-align: right;
}

.seed {
    display: inline-block;
    width: 45%;
    margin-left: 5%;
    margin-top: -10px;
}

.count {
    display: inline-block;
    width: 45%;
    margin-left: 5%;
    margin-top: -10px;
}

.count_r {
    display: inline-block;
    width: 55%;
    margin-left: 5%;
    margin-top: -10px;
}

.words_r {
    display: inline-block;
    width: 55%;
    margin-left: 5%;
    margin-top: 20px;
}

.papers {
    width: inherit;
    height: 81%;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-top: -10px;
}

.flex-container {
    width: 100%;
    height: 100%;
    margin: 0;
    list-style: none;
    display: block;
    display: flex;
    justify-content: flex-start;
}

.flex-container_r {
    width: 100%;
    height: 100%;
    margin: 0;
    list-style: none;
    display: block;
    display: flex;
    justify-content: flex-start;
}

.flex-item3 {
    background: #fff;
    padding: 5px;
    width: 32%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

.flex-item4 {
    background: #fff;
    padding: 5px;
    width: 24%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

.flex-item5 {
    background: #fff;
    padding: 5px;
    width: 19%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

.flex-item6 {
    background: #fff;
    padding: 5px;
    width: 32%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

.flex-item7 {
    background: #fff;
    padding: 5px;
    width: 24%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

.flex-item8 {
    background: #fff;
    padding: 5px;
    width: 24%;
    margin-top: 1%;
    margin-left: 1%;
    text-align: center;
}

input[type=range] {
    width: 280px;
    margin-right: 40px;
}

input[type=range] {
    border: 0px solid white;
}

input[type=range]+.thumb {
    background-color: #60b0f4;
}

input[type=range]::-webkit-slider-thumb {
    background: #60b0f4;
}

.input-field input[type=text] {
    height: 2rem;
    width: 280px;
    font-size: 0.9rem;
    margin-left: 20px;
    padding-top: 10px;
    margin-right: 40px;
    float: right;
}

.input-field input[type=text]:focus {
    border-bottom: 1px solid #42a5f5 !important;
    box-shadow: 0 1px 0 0 #42a5f5 !important;
    height: 2rem;
    font-size: 0.9rem;
}

.input-field input[name=passphrase] {
    height: 0.6rem;
    width: 80%;
    font-size: 0.8rem;
    float: left;
    margin-top: -9px;
}

.input-field input[name=passphrase]:focus {
    border-bottom: 1px solid #42a5f5 !important;
    box-shadow: 0 1px 0 0 #42a5f5 !important;
    height: 0.7rem;
    width: 80%;
    font-size: 1rem;
}

#sel {
    float: right;
    margin-top: -10px;
}

#sel1 {
    float: right;
    margin-top: -20px;
}

.nice-select {
    width: 70px;
    height: 32px;
    line-height: 30px;
    font-size: 13px;
    padding-left: 8px;
    text-overflow: ellipsis;
}

.nice-select .list {
    width: 70px;
    font-size: 13px;
    overflow-y: scroll;
    text-overflow: ellipsis;
}

.nice-select.open .list {
    max-height: 200px;
    overflow-y: scroll;
}

.nice-select .option {
    padding-left: 8px;
    line-height: 30px;
    min-height: 32px;
    text-overflow: ellipsis;
    overflow-y: scroll;
}

.nice-select .list:hover .option:hover {
    background-color: #42a5f5 !important;
    color: #fff !important;
}

.nice-select:hover {
    border-color: #42a5f5;
}

.nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #42a5f5;
}

.copy-text {
    display: inline-block;
    width: 90%;
    margin-left: 5%;
    float: left;
}

#button {
    margin: 0 auto;
    margin-top: 25px;
    font-size: 12px;
}

.top-wrapper {
    position: relative;
    overflow: hidden;
    background-color: #ebeff2;
}

/*@media(min-width: 1200px) {
    .top-wrapper {
        background-color: transparent;
    }
}*/

.top-wrapper:before {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 315px;
    background-image: linear-gradient(to bottom, #e2e5ea, #e6e8ec 5%, #eaedf1 10%, #eff1f5 16%, #f2f5f9 21%, #f2f5fa 26%, #f4f8ff 32%, #f6f9ff 37%, #f6f9ff 42%, #f2f5fb 48%, #f0f2f6 53%, #edeff2 59%, #eceff3 64%, #edeff2);
}

@media(min-width: 750px) {
    .top-wrapper:before {
        height: 802px;
    }
}

@media(min-width: 1200px) {
    .top-wrapper:before {
        height: 977px;
    }
}

.top {
    padding-top: 13.8666667%;
    box-sizing: border-box;
    background-image: url(/static/media/bg.eb7746f2.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    padding-bottom: 56px;
    position: relative;
}

@media(min-width:750px) {
    .top {
        padding-top: 127px;
        background-size: 125%;
        padding-bottom: 105px;
    }
}

@media(min-width: 1200px) {
    .top {
        padding-top: 25px;
        background-size: 1184px;
        padding-bottom: 84px;
    }
}

@media(min-width: 1600px) {
    .top {
        padding-top: 72px;
        background-size: inherit;
        padding-bottom: 103px;
    }
}

.pages {
    padding-top: 20px;
    padding-bottom: 20px;
}

.container {
    max-width: 480px;
    margin: 0 auto;
    position: relative;
}

@media(min-width: 750px) {
    .container {
        max-width: 752px;
    }
}

.content {
    font-family: 'Charter-Roman', serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.91);
    line-height: 1.33;
    letter-spacing: 0.1px;
    /*max-width: 53.33333333%;*/
    font-size: 12px;
    margin-top: 24px;
    margin-right: 24px;
    margin-left: auto;
    margin-bottom: 26px;
}

@media(min-width: 750px) {
    .content {
        margin-top: 125px;
        margin-bottom: 52px;
        font-size: 24px;
        max-width: 68.2666667%;
    }
}

@media(min-width: 1200px) {
    .content {
        margin-top: 50px;
        max-width: 100%;
        margin-left: 25px;
        font-size: 18px;
        margin-bottom: 23px;
        margin-left: 25%;
        line-height: 1.78;
        margin-right: 0;
    }
}

@media(min-width: 1350px) and (max-width: 1500px) {
    .content {
        margin-left: 15%;
    }
}

@media(min-width: 1600px) {
    .content {
        margin-top: 96px;
        margin-bottom: 57px;
        line-height: 1.78;
        margin-right: 0;
        margin-left: 64px;
        letter-spacing: 0.2px;
    }
}

.seed-container {
    position: relative;
    padding: 30px 25px 50px;
    opacity: 0.95;
    border-radius: 2px;
    box-shadow: 4px 14px 38px 0 rgba(0, 0, 0, 0.03), 4px 8px 5px 0 rgba(113, 123, 122, 0.11), 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border: solid 1px rgba(255, 255, 255, 0.82);
    min-height: 210px;
    box-sizing: border-box;
    /*margin-bottom: 56px;*/
}

@media(min-width:750px) {
    .seed-container {
        padding: 80px 64px 54px;
        min-height: 400px;
        /*margin-bottom: 105px;*/
    }
}

@media(min-width:1200px) {
    .seed-container {
        padding: 56px 56px 43px 63px;
        min-height: 256px;
        /*margin-bottom: 84px;*/
    }
}

@media(min-width:1600px) {
    .seed-container {
        min-height: 320px;
        padding: 76px 56px 55px 63px;
        /*margin-bottom: 103px;*/
    }
}


.seed-container--focused {
    box-shadow: 4px 14px 38px 0 rgba(0, 0, 0, 0.1), 4px 8px 5px 0 rgba(113, 123, 122, 0.43), 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}

.seed-container__words-count {
    position: absolute;
    top: 10px;
    left: 18px;
    font-size: 11px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.33;
    color: rgba(0, 0, 0, 0.4);
    background-color: transparent;
    border: none;
    padding: 0;
}

@media(min-width: 750px) {
    .seed-container__words-count {
        top: 43px;
        left: 46px;
        font-size: 18px;
    }
}

@media(min-width:1200px) {
    .seed-container__words-count {
        top: 23px;
        left: 54px;
    }
}

.seed-container__generate, .seed-container__clear {
    position: absolute;
    top: 10px;
    right: 24px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.71;
    color: rgba(0, 0, 0, 0.4);
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

@media(min-width:750px) {
    .seed-container__generate, .seed-container__clear {
        font-size: 18px;
        line-height: 1.33;
        top: 43px;
        right: 46px;
    }
}

@media(min-width:1200px) {
    .seed-container__generate, .seed-container__clear {
        font-size: 18px;
        line-height: 1.33;
        top: 23px;
        right: 47px;
    }
}

.seed-container__generate:hover, .seed-container__clear:hover {
    cursor: pointer;
}

.seed-container__generate:focus, .seed-container__clear:focus {
    outline: none;
}

.seed-container__form {
    position: relative;
    min-height: 42px;
    max-width: 80%;
}

@media(min-width:750px) {
    .seed-container__form {
        min-height: 138px;
        max-width: 100%;
    }
}

@media(min-width:1200px) {
    .seed-container__form {
        min-height: 69px;
    }
}

@media(min-width:1600px) {
    .seed-container__form {
        min-height: 101px;
    }
}

.seed-container__textarea {
    position: absolute;
    top: 0;
    left: 0;
    resize: none;
    width: 100%;
    height: 100%;
    border: none;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 1.71;
    font-weight: normal;
    -webkit-text-fill-color: transparent;
    background-color: transparent;
    padding: 0;
    padding-right: 5px;
    box-sizing: border-box;
}

@media(min-width: 750px) {
    .seed-container__textarea {
        font-size: 36px;
        padding-right: 10px;
    }
}

@media(min-width: 1200px) {
    .seed-container__textarea {
        font-size: 28px;
        padding-right: 8px;
    }
}

.seed-container__textarea::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    text-shadow: none;
    -webkit-text-fill-color: initial;
}

.seed-container__textarea:focus {
    outline: none;
}

.seed-container__text {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 1.71;
    margin: 0;
    padding-right: 5px;
    color: #000;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

@media(min-width: 750px) {
    .seed-container__text {
        font-size: 36px;
        padding-right: 10px;
    }
}

@media(min-width: 1200px) {
    .seed-container__text {
        font-size: 28px;
        padding-right: 8px;
    }
}

.seed-container__text--invalid {
    color: rgba(127, 23, 23, 0.9);
}

.seed-container__placeholder {
    pointer-events: none;
    position: absolute;
    top: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.72);
    font-size: 18px;
    line-height: 1.71;
}

@media(min-width: 750px) {
    .seed-container__placeholder {
        font-size: 36px;
        line-height: 1.33;
    }
}

@media(min-width:1200px) {
    .seed-container__placeholder {
        font-size: 28px;
        line-height: 1.71;
    }
}

.seed-container__placeholder--focus {
    opacity: 0.5;
}

.seed-container__placeholder--hidden {
    display: none;
}

.caption {
    font-family: 'Charter-Roman', serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.72);
    margin-top: 0;
    margin-bottom: 0;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 1.73;
    margin-top: 30px;
}

@media(min-width:750px) {
    .caption {
        font-size: 15px;
        line-height: 2.13;
        letter-spacing: 0.2px;
    }
}

@media(min-width:1200px) {
    .caption {
        line-height: 1.87;
        letter-spacing: -0.1px;
    }
}

.caption--restore-seed {
    margin-top: 0;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 21px;
    box-sizing: border-box;
    padding: 0 15px;
}

@media(min-width: 750px) {
    .caption--restore-seed {
        bottom: 24px;
    }
}

@media(min-width: 1600px) {
    .caption--restore-seed {
        bottom: 14px;
    }
}

.caption--invalid {
    color: #8a2c2c;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

/*.page {
    border-radius: 2px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.51), rgba(250, 250, 250, 0.51));
    border: solid 1px #dfe4e0;
    border-bottom: 2px solid #a3b8c1;
}*/

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
